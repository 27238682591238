@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;700&family=Bellefair&family=Inter:wght@200;400;700&display=swap");

/* ======================================================================================== */
/* CUSTOM PROPERTIES */
/* ======================================================================================== */

:root {
	scroll-behavior: smooth;
	overflow-x: hidden;

	/* colors */
	--clr-dark: 230 35% 7%;
	--clr-light: 231 77% 90%;
	--clr-white: 0 0% 100%;

	--btn-green: hsl(146, 68%, 55%);
	--btn--blue: hsl(210, 78%, 56%);
	--btn-grey: hsl(230, 22%, 74%);

	/* font-sizes */
	/* --fs-900: clamp(5rem, 7vw + 1rem, 9.375rem); */
	--fs-800: 3.5rem;
	--fs-700: 1.5rem;
	--fs-600: 1.2rem;
	--fs-500: 1rem;
	--fs-400: 0.9375rem;
	--fs-300: 1rem;
	--fs-300: 0.8rem;
	--fs-200: 0.875rem;

	/* font-families */
	--ff-serif: "Bellefair", serif;
	--ff-sans-cond: "Barlow Condensed", sans-serif;
	--ff-sans-normal: "Inter", sans-serif;
}

.light-theme {
	--clr-primary: hsl(191, 51%, 61%);
	--clr-bg-nav: hsl(192, 9%, 90%);
	--clr-bg-body: hsl(0, 0%, 100%);
	--clr-bg-top: hsl(225, 100%, 98%);
	--clr-bg-container: hsl(240, 14%, 99%);
	--clr-bg-card: hsl(0, 0%, 100%);
	--clr-bg-bar: hsl(234, 33%, 94%);
	--clr-bg-img: hsl(220, 11%, 95%);
	--clr-bg-img--hover: hsl(230, 31%, 89%);
	--clr-text-grey: hsl(228, 12%, 44%);
	--clr-text-primary: hsl(228, 9%, 22%);
	--clr-border: hsl(230, 31%, 89%);
}

.dark-theme {
	--clr-primary: hsl(220, 51%, 61%);
	--clr-bg-nav: hsl(219, 89%, 15%);
	--clr-bg-body: hsl(230, 17%, 14%);
	--clr-bg-top: hsl(232, 19%, 15%);
	--clr-bg-container: hsl(232, 19%, 15%);
	--clr-bg-card: hsl(230, 27%, 27%);
	--clr-bg-bar: hsl(230, 30%, 32%);
	--clr-bg-img: hsl(230, 27%, 27%);
	--clr-bg-img--hover: hsl(230, 30%, 32%);
	--clr-text-grey: hsl(228, 34%, 66%);
	--clr-text-primary: hsl(0, 0%, 100%);
	--clr-border: hsl(230, 31%, 89%);
}

@media (min-width: 35em) {
	:root {
		/* font-sizes */
		--fs-800: 5rem;
		--fs-700: 2.5rem;
		--fs-600: 1.5rem;
		--fs-500: 1.25rem;
		--fs-400: 1rem;
	}
}

@media (min-width: 45em) {
	:root {
		/* font-sizes */
		--fs-800: 6.25rem;
		--fs-700: 3.5rem;
		--fs-600: 2rem;
		--fs-500: 1.75rem;
		--fs-400: 1.125rem;
	}
}

/* ======================================================================================== */
/* RESET */
/* ======================================================================================== */

*,
*::after,
*::before {
	box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
picture {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400;
}

p {
	font-size: var(--fs-200);
	font-weight: 200;
}

/* set up the body */

body {
	font-family: var(--ff-sans-normal);
	font-size: var(--fs-400);
	color: var(--clr-text-primary);
	background-color: var(--clr-bg-body);
	line-height: 1.5;
	min-height: 100vh;
	width: 100vw;
	margin-inline: auto;
	display: grid;
	place-content: center center;
	overflow-x: hidden;
}

main {
	--flow-space: 3em;
}

/* make images easier to work with  */
img,
picture {
	max-width: 100%;
	display: block;
}

/* make forms easier to work with */
input,
button,
textarea,
select {
	font: inherit;
}

a {
	text-decoration: none;
	color: var(--clr-text-grey);
}

ul {
	padding: 0;
}

li {
	list-style: none;
}

/* ======================================================================================== */
/* UTILITY CLASSES */
/* ======================================================================================== */
.flex {
	display: flex;
	gap: var(--gap, 1rem);
}

.grid {
	display: grid;
	gap: var(--gap, 1rem);
}

.flow > * + *,
/* same result */
.flow > *:where(:not(:first-child)) {
	margin-top: var(--flow-space, 1rem);
}

.flow-space--small {
	--flow-space: 0.5rem;
}

.container {
	padding: 1em;
	margin-inline: auto;
	max-width: 80rem;
	width: 95%;
	background-color: var(--clr-bg-container);
}

.center {
	text-align: center;
}

.react-icons {
	font-size: 2em;
	cursor: pointer;
	z-index: 3;
	color: var(--clr-primary);
}

.react-icons.linkedin {
	color: #0072b1;
}

.react-icons.github {
	color: #000;
}
.react-icons--small {
	font-size: 1.3rem;
	color: var(--clr-primary);
}

.underline {
	width: 6em;
	height: 3px;
	background-color: var(--clr-primary);
	margin-inline: auto;
	margin-top: 0.4em;
}

.underline--sm {
	width: 3em;
	height: 3px;
	background-color: var(--clr-primary);
	margin-inline: auto;
	margin-top: 0.4em;
}

/* screen-reader-only */
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

/* ======================================================================================== */
/* TYPOGRAPHY */
/* ======================================================================================== */

.ff-serif {
	font-family: var(--ff-serif);
}
.ff-sans-cond {
	font-family: var(--ff-sans-cond);
}
.ff-sans-normal {
	font-family: var(--ff-sans-normal);
}

.letter-spacing-1 {
	letter-spacing: 4.75px;
}
.letter-spacing-2 {
	letter-spacing: 2.7px;
}
.letter-spacing-3 {
	letter-spacing: 2.35px;
}
.letter-spacing-4 {
	letter-spacing: 1.2px;
}

.uppercase {
	text-transform: uppercase;
}

.fs-900 {
	font-size: var(--fs-900);
}
.fs-800 {
	font-size: var(--fs-800);
}
.fs-700 {
	font-size: var(--fs-700);
}
.fs-600 {
	font-size: var(--fs-600);
}
.fs-500 {
	font-size: var(--fs-500);
}
.fs-400 {
	font-size: var(--fs-400);
}
.fs-300 {
	font-size: var(--fs-300);
}
.fs-200 {
	font-size: var(--fs-200);
}

.fs-900,
.fs-800,
.fs-700,
.fs-600,
.fs-500 {
	line-height: 1.1;
}

.fw-200 {
	font-weight: 200;
}
.fw-400 {
	font-weight: 400;
}
.fw-700 {
	font-weight: 700;
}

.text-lighter {
	color: var(--clr-text-grey);
}

.tag--html {
	color: #9b5de5;
}
.tag--css {
	color: #f15bb5;
}
.tag--js {
	color: #8ac926;
}
.tag--react {
	color: #fea240;
}
.tag--api {
	color: #00bbf9;
}
.tag--all {
	color: #004ef5;
}

/* ======================================================================================== */
/* NAVIGATION */
/* ======================================================================================== */
.nav {
	position: fixed;
	width: 100vw;
	top: 0;
	right: 0;
	left: 0;
	background-color: var(--clr-bg-bar);
	z-index: 500;
}

.nav-box {
	align-items: center;
	justify-content: space-between;
	padding-inline: 1em;
	margin-inline: auto;
	max-width: 80rem;
	width: 95%;
}

.nav-item a {
	color: var(--clr-primary);
}

/* ==================== */
/* TOGGLE-BTN */
/* ==================== */

.btn {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 3.2rem;
	height: 1.6rem;
	background-color: var(--clr-primary);
	border-radius: 7px;
	cursor: pointer;
	position: relative;
	border: none;
}

.btn.dark:hover {
	background-color: var(--clr-bg-card);
}

.btn.dark:hover .circle {
	background-color: var(--clr-primary);
}

.btn.dark:hover .react-icons--sun {
	color: var(--clr-primary);
}

.circle {
	height: 1.2rem;
	width: 1.4rem;
	border-radius: 50%;
	border-radius: 7px;
	background-color: var(--clr-bg-top);
	position: absolute;
	top: 0.2rem;
	left: 1.6rem;
	transition: transform 0.2s ease-in;
}

.circle.dark {
	transform: translateX(-1.4rem);
}
.btn.light {
	background-color: var(--clr-border);
}
.btn.light:hover {
	background-color: var(--clr-primary);
}
.btn.light:hover .react-icons--moon {
	color: var(--clr-border);
}

.react-icons--moon {
	font-size: 1rem;
	color: var(--clr-primary);
}
.react-icons--sun {
	font-size: 1rem;
	color: var(--clr-dark);
}
.react-icons.github.dark {
	color: var(--clr-border);
}

/* ======================================================================================== */
/* LAYOUT */
/* ======================================================================================== */

/* App */
.app {
	grid-template-areas:
		"header"
		"featured"
		"projects";
	padding-block-start: 4em;
}

/* Header */
.header {
	grid-area: header;
	grid-template-areas:
		" img icons-box "
		" about about "
		" skills skills ";
	grid-template-columns: 2fr 3fr;
	--gap: 1rem;
	padding-inline: 1em;
	position: relative;
}

.icons-box {
	grid-area: icons-box;
	grid-template-areas: "about" "social" "contact";
	--gap: 1em;
}

.icons-box > * {
	--gap: 1.2em;
}
.about-box {
	grid-area: about;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	--gap: 0;
}
.social-box {
	grid-area: social;
	align-items: center;
	justify-content: center;
	border-top: 1px solid var(--clr-border);
	border-bottom: 1px solid var(--clr-border);
	padding: 1em;
}
.contact-box {
	grid-area: contact;
	align-items: center;
	justify-content: center;
}

.about {
	grid-area: about;
}

.skills {
	grid-area: skills;
	margin-top: 1.5em;
}

/* Featured */
.featured {
	grid-area: featured;
	grid-template-areas:
		". title  ."
		". arrows  ."
		". activeSlide .";
	grid-template-columns: 10% 80% 10%;
	--gap: 0.5em;
	justify-content: center;
	padding-block-start: 4em;
}

/* Projects */
.projects {
	grid-area: projects;
	padding-block-start: 4em;
}

.cards-container {
	grid-template-columns: 1fr;
	--gap: 1em;
}

@media (min-width: 35em) {
	.header {
		grid-template-areas:
			". img  . "
			" icons-box icons-box icons-box"
			" about about about"
			"skills skills .";
		grid-template-columns: 1fr 2fr 1fr;
		--gap: 1.5rem;
	}

	.icons-box {
		grid-template-areas: "about social contact";
		border-bottom: 1px solid var(--clr-border);
	}

	.icons-box > * {
		padding-bottom: 1em;
		--gap: 1em;
	}

	.about-box {
		align-items: flex-start;
		--gap: 0;
	}

	.social-box {
		border-top: none;
		border-bottom: none;
		border-left: 1px solid var(--clr-border);
		border-right: 1px solid var(--clr-border);
		padding: 0;
		padding-inline: 1em;
	}

	.skills {
		margin-top: 0;
	}

	.title-box--sm {
		margin: 1em auto;
	}

	.cards-container {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (min-width: 45em) {
	.header {
		grid-template-areas:
			"img icons-box icons-box"
			"img about skills";
		grid-template-columns: 1.5fr 3fr 3fr;
	}

	.icons-box {
		white-space: nowrap;
	}

	.featured {
		grid-template-columns: 25% 45% 25%;
	}

	.title-box {
		margin: 2em auto;
	}

	.cards-container {
		grid-template-columns: repeat(3, 1fr);
	}

	.title-box--sm {
		margin: 1em auto;
	}
}

/* ======================================== APP ======================================== */
.app {
	margin-bottom: 4em;
}

/* ====================================== HEADER ====================================== */

.profile-img {
	grid-area: img;
	position: relative;
	aspect-ratio: 1;
	z-index: 0;
	padding: 0.3em;
}

.profile-img img,
.profile-img source {
	border-radius: 50%;
	z-index: 200;
}

.profile-img::before {
	content: "";
	position: absolute;
	width: calc(100% + 0.5em);
	width: 100%;
	height: calc(100% + 0.5em);
	height: 100%;
	top: 0;
	top: -0.3em;
	left: 0;
	left: -0.3em;
	background-color: var(--clr-primary);
	border-radius: 50%;
	z-index: -1;
}

/* frontend mentor icon-img */
.fm-img {
	width: 2em;
}

/* */
/* TOOLTIP */
/* */
.tooltip {
	position: relative;
	display: flex;
	align-items: center;
}

.tooltip-text {
	position: absolute;
	top: -60%;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2;
	white-space: nowrap;
	visibility: hidden;

	color: var(--clr-primary);
	background-color: var(--clr-bg-top);
	border-radius: 7px;
	padding-inline: 0.6em;
}
.tooltip:hover .tooltip-text {
	visibility: visible;
}

/* */
/* PROGRESS BAR */
/* */

.progress-container {
	--gap: 0.4em;
}

.progress {
	align-items: center;
	justify-content: space-evenly;
}

.progress p {
	width: 5em;
}

.progress-bar-bg {
	width: 70%;
	height: 5px;
	background: var(--clr-bg-bar);
}

.progress-bar {
	height: 100%;
	background: var(--clr-primary);
}

/* ===================================== FEATURED ===================================== */

.title-box {
	grid-area: title;
	margin: 2em auto;
}

.arrows-box {
	grid-area: arrows;
	text-align: center;
	margin-block-end: 0.5em;
}

.card--featured {
	grid-area: activeSlide;
	border-radius: 0.5em;
	overflow: hidden;
	z-index: 0;
	background-color: var(--clr-bg-card);
	opacity: 0;
	transform: translateX(0) scale(1);
	transition: transform 0.3s linear, opacity 0.3s linear;
}

.rightSlide {
	grid-area: activeSlide;
	transform: translateX(80%) scale(0.55);
	opacity: 0;
	z-index: 1;
	align-self: center;
}
.leftSlide {
	grid-area: activeSlide;
	transform: translateX(-80%) scale(0.55);
	opacity: 0;
	z-index: 1;
	align-self: center;
}
.activeSlide {
	grid-area: activeSlide;
	transform: translateX(0) scale(1);
	z-index: 2;
	opacity: 1;
}

@media (min-width: 45em) {
	.card--featured {
		transform: translateX(0) scale(0);
		transition: transform 0.3s linear, opacity 0.3s linear 0.2s;
	}

	.rightSlide {
		transform: translateX(80%) scale(0.55);
		opacity: 1;
	}
	.leftSlide {
		transform: translateX(-80%) scale(0.55);
		opacity: 1;
	}
	.activeSlide {
		transform: translateX(0) scale(1);
		z-index: 2;
		opacity: 1;
	}
}

/* ===================================== FILTER ===================================== */
.title-box--sm {
	margin: 1.5em auto;
}

.filter-container {
	justify-content: center;
	flex-wrap: wrap;
	margin-block: 1em;
}

.btn-container {
	flex-wrap: wrap;
	justify-content: center;
}

.filter-btn {
	border: 2px solid;
	border-radius: 7px;
	padding-inline: 1em;
	cursor: pointer;
	transform: translateY(0);
	transition: transform 0.2s linear;
	position: relative;
}

.filter-btn::before {
	position: absolute;
	content: "";
	inset: 0;
	background-color: currentColor;
	opacity: 0.2;
}

.filter-btn:hover {
	transform: translateY(-10%);
}

.filter-btn:active {
	transform: translateY(5%);
}

/* ======================================== CARD / PROJECT ======================================== */
.card {
	background-color: var(--clr-bg-card);
	border-radius: 0.5em;
	overflow: hidden;
}

.img-container {
	padding: 1em;
	background-color: var(--clr-bg-img);
	transform: scale(1);
	transition: transform 0.3s ease-in-out;
}

.img-container:hover {
	transform: scale(1.08);
}

.content-container {
	padding: 1em;
}

.tags-container {
	justify-content: space-between;
	align-items: center;
	margin-block: 0.7em;
}

/* ===================================== FOOTER ===================================== */
.footer {
	background-color: var(--clr-primary);
	color: var(--clr-bg-top);
	padding-block: 0.5em;
}

.footer a {
	color: var(--clr-text-primary);
}
